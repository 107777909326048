/* App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@layer base {
  :root {
    --bg-primary: #121212;
    --bg-secondary: #1E1E1E;
    --text-primary: #FFFFFF;
    --text-secondary: #B3B3B3;
    --accent-start: #21D4FD;
    --accent-end: #B721FF;
    --accent-start-rgb: 124, 58, 237;
    --accent-end-rgb: 99, 102, 241;
  }
}

/* Allow horizontal scroll but prevent vertical overscroll */
html {
  overscroll-behavior-y: none;
  height: 100%;
  -webkit-text-size-adjust: none;
}

body {
  height: 100%;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--bg-primary);
}

/* Modified touch behavior */
html {
  @apply select-none;
}

/* Prevent zoom on input focus for mobile */
input,
textarea,
select {
  font-size: 16px !important; /* Prevents iOS zoom */
  max-height: 100%;
  touch-action: manipulation;
}

/* Match form styling for autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgb(18, 18, 18, 0.5) inset !important; /* matches bg-bg-primary/50 */
  -webkit-text-fill-color: #FFFFFF !important; /* matches text-text-primary */
  border-radius: 0.75rem !important; /* matches rounded-xl */
  border: 1px solid rgba(255, 255, 255, 0.1) !important; /* matches border border-white/10 */
  transition: background-color 5000s ease-in-out 0s;
}

/* Keep focus state consistent */
input:-webkit-autofill:focus {
  border-color: var(--accent-start) !important;
  box-shadow: 0 0 0 1px var(--accent-start) !important; /* matches focus:ring-1 focus:ring-accent-start */
}

/* Firefox autofill */
input:autofill {
  background-color: rgb(18, 18, 18, 0.5) !important;
  color: #FFFFFF !important;
  border-radius: 0.75rem !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

/* Firefox focus state */
input:autofill:focus {
  border-color: var(--accent-start) !important;
  box-shadow: 0 0 0 1px var(--accent-start) !important;
}

/* Allow horizontal scrolling while preventing other unwanted behaviors */
body {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-x pan-y;
}

/* Remove -webkit-touch-callout restriction to allow natural touch behavior */
.scrollable-content {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  touch-action: pan-x;
}

/* Viewport settings that don't restrict touch behavior */
meta[name="viewport"] {
  content: "width=device-width, initial-scale=1.0, maximum-scale=1.0";
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-primary);
}

::-webkit-scrollbar-thumb {
  background-color: var(--text-secondary);
  border-radius: 4px;
  border: 2px solid var(--bg-primary);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-secondary);
}

/* Firefox scrollbar customization */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--bg-secondary) var(--bg-primary);
}

/* Custom scrollbar class */
.custom-scrollbar {
  /* WebKit (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--bg-secondary);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-primary);
    border-radius: 8px;
    border: 3px solid var(--bg-primary);
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--text-secondary);
  }

  &::-webkit-scrollbar-corner {
    background-color: var(--bg-primary);
  }

  /* Firefox */
  scrollbar-width: auto;
  scrollbar-color: var(--text-secondary) var(--bg-primary);
}
.grid-cell-input {
  font-size: 12px !important; /* This will override the previous !important */
}

.ag-theme-material-dark .ag-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;  /* Remove default cell padding */
}

/* If you need to target only the image column */
.ag-theme-material-dark .image-column {  /* Add this class to your column def */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.ag-theme-material-dark {
  /* Target the header checkbox specifically */
  & .ag-header-select-all {
    padding: 0;
    margin: 0;
  }

  /* The wrapper around the checkbox */
  & .ag-header-cell-comp-wrapper {
    padding: 0;
    margin: 0;
  }

  /* The actual checkbox in header */
  & .ag-checkbox-input-wrapper {
    margin: 0;
    padding: 0;
  }

  /* The header cell containing select-all checkbox */
  & .ag-header-cell[col-id="selected"] {
    padding: 0;
    margin: 0;
  }
  
  /* The inner container of header cell */
  & .ag-header-cell[col-id="selected"] .ag-header-cell-comp {
    padding: 0;
  }
  
  /* Ultra transparent AG Grid theme */
  &.ag-theme-transparent {
    background: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    
    /* Force transparency on most elements but allow for required field highlighting */
    & *:not(.ag-checkbox-input-wrapper):not(.ag-checkbox-input-wrapper input):not([style*="border: 2px solid rgba(220, 38, 38, 0.5)"]) {
      background: none !important;
      background-color: transparent !important;
      background-image: none !important;
      backdrop-filter: none !important;
    }
    
    /* Preserve box-shadow and border properties for required field highlighting */
    & .ag-cell[style*="border: 2px solid rgba(220, 38, 38, 0.5)"] {
      background-color: transparent !important;
      background-image: none !important;
      background: none !important;
      box-shadow: inset 0 0 0 1px rgba(220, 38, 38, 0.2) !important;
      border: 2px solid rgba(220, 38, 38, 0.5) !important;
    }
    
    /* Allow only selected rows to have the subtle highlight */
    & .ag-row.selected-row {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }
    
    /* Target specific elements that might still have backgrounds */
    & .ag-root-wrapper,
    & .ag-root,
    & .ag-body-viewport,
    & .ag-center-cols-container,
    & .ag-center-cols-viewport,
    & .ag-body-horizontal-scroll-viewport,
    & .ag-body-horizontal-scroll,
    & .ag-header,
    & .ag-header-viewport,
    & .ag-header-container,
    & .ag-pinned-left-header,
    & .ag-pinned-left-cols-container,
    & .ag-pinned-left-cols-viewport,
    & .ag-pinned-right-header,
    & .ag-pinned-right-cols-container,
    & .ag-pinned-right-cols-viewport,
    & .ag-floating-top-container,
    & .ag-floating-top-viewport,
    & .ag-floating-bottom-container,
    & .ag-floating-bottom-viewport,
    & .ag-body-horizontal-scroll-container,
    & .ag-full-width-container,
    & .ag-sticky-top-container,
    & .ag-full-width-viewport,
    & .ag-cell,
    & .ag-cell-wrapper,
    & .ag-header-cell,
    & .ag-header-group-cell,
    & .ag-header-cell-comp-wrapper,
    & .ag-header-cell-text {
      background-color: transparent !important;
      background-image: none !important;
      background: none !important;
    }
    
    /* Remove vertical grid lines but keep horizontal ones */
    & .ag-cell {
      border-right: none !important;
      border-left: none !important;
    }
    
    & .ag-header-cell {
      border-right: none !important;
      border-left: none !important;
    }
    
    & .ag-row {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
    
    & .ag-header {
      border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
    }
    
    /* Remove all borders to make it fully transparent */
    & .ag-root-wrapper,
    & .ag-header,
    & .ag-header-cell,
    & .ag-header-group-cell,
    & .ag-cell,
    & .ag-row {
      border-color: rgba(255, 255, 255, 0.1) !important;
    }
    
    /* Make rows and hover states 100% transparent */
    & .ag-row,
    & .ag-row:hover:not(.selected-row),
    & .ag-row-even,
    & .ag-row-odd {
      background-color: transparent !important;
      background: none !important;
    }
    
    /* Selected row style - exactly 5% white opacity */
    & .ag-row.selected-row {
      background-color: rgba(255, 255, 255, 0.05) !important;
    }
  }
  
  & .ag-header-cell[col-id="selected"] .ag-header-cell-comp {
    margin: 0;
  }
}

.drag-icon {
  opacity: 0.8;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Audio player glow effect */
.hover\:shadow-glow:hover {
  box-shadow: 0 0 15px rgba(var(--accent-start-rgb), 0.5), 0 0 30px rgba(var(--accent-end-rgb), 0.3);
  transform: scale(1.05);
}

.drag-icon:hover {
  transform: scale(1.1);
  opacity: 1;
}

.drag-icon:active {
  transform: scale(0.95);
}

.navigation { 
  width: 250px; 
}